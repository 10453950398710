import React, { useState, useContext, useEffect } from 'react';
// import FileInput from "../Images/folder.png";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, storage, db } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
// import ProgressBar from "@ramonak/react-progress-bar";
import TopBarProgress from "react-topbar-progress-indicator";
import LoadingBar from 'react-top-loading-bar';
import { AuthContext } from "../context/AuthContext";
import swal from '@sweetalert/with-react';

const Register = (props) => {
  document.title = "Drake Forum • Register"

  const [err, setErr] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [progresspercent, setProgressPercent] = useState();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [text, setText] = useState("");
  const [pfpFile, setPFPFile] = useState(null);
  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const EMail = e.target[1].value;
    const Password = e.target[2].value;
    const File = pfpFile;

    if (File !== null) {
      try {

        const res = await createUserWithEmailAndPassword(auth, EMail, Password);

        const storageRef = ref(storage, "User's_Profile_Picture/" + displayName);
        const uploadTask = uploadBytesResumable(storageRef, File);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const roundedprogress = Math.round(progress);
            setProgressPercent(roundedprogress);
          },
          (error) => {
            setErr(true);
            setLoading(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              // console.log('File available at', downloadURL);
              await updateProfile(res.user, {
                displayName,
                photoURL: downloadURL,
              });
              await setDoc(doc(db, "Users", res.user.uid), {
                uid: res.user.uid,
                displayName,
                EMail,
                photoURL: downloadURL,
                Status: "Active Now",
                Password: Password,
                Device_IP: props.IPAddress,
                LoginTime: combinedCurrentDateTime,
                LogoutTime: '',
                NoOfQuestionAnswered: 0,
                NoOfQuestionAsked: 0,
                SetStatusDone: false,
              });
            //   await setDoc(doc(db, "UserChats", res.user.uid), {});
            //   await setDoc(doc(db, "MessageData", res.user.uid), {});
              navigate("/")
            }
            );

          });

      } catch (err) {
        setErr(true);
        setErrorCode(err.code);
        setErrorMessage(err.message);
        setLoading(false);
      }
    } else {
      try {

        const res = await createUserWithEmailAndPassword(auth, EMail, Password);

        await updateProfile(res.user, {
          displayName,
          photoURL: "https://firebasestorage.googleapis.com/v0/b/drake-forum-257980.appspot.com/o/User's_Profile_Picture%2FDefault_Profile_Pic.png?alt=media&token=c8c2cf6c-8671-4d0e-934c-32ca8dc34e26",
        });
        await setDoc(doc(db, "Users", res.user.uid), {
          uid: res.user.uid,
          displayName,
          EMail,
          photoURL: "https://firebasestorage.googleapis.com/v0/b/drake-forum-257980.appspot.com/o/User's_Profile_Picture%2FDefault_Profile_Pic.png?alt=media&token=c8c2cf6c-8671-4d0e-934c-32ca8dc34e26",
          Status: "Active Now",
          Password: Password,
          Device_IP: props.IPAddress,
          LoginTime:combinedCurrentDateTime,
          LogoutTime:'',
          NoOfQuestionAnswered:0,
          NoOfQuestionAsked:0,
          SetStatusDone: false,
        });
        // await setDoc(doc(db, "UserChats", res.user.uid), {});
        // await setDoc(doc(db, "MessageData", res.user.uid), {});
        navigate("/")

      } catch (err) {
        setErr(true);
        setLoading(false);
      }
    }

  }

  const handleImageChange = e => {
    const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
    if (!validExtensions.includes(e.target.files[0].type)) {
      swal("File Format Not Supported")
      setText("");
      setLoading(false);
      setPFPFile(null);
      return false
    } else {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        swal("File Size Should Be Less Than 10 MB")
        setText("");
        setLoading(false);
        setPFPFile(null);
        return false
      } else {
        setText(e.target.files[0].name)
        setPFPFile(e.target.files[0])
      }
    }
  }

  TopBarProgress.config({
    barThickness: 8
  });

  if (!loading) {
    if (currentUser) {
      navigate("/")
    }
  }

  return (
    <>
      <body id="FormBody">
        {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
        <div className="login">
          <div className="login__content">
            <div className="login__img">
              <img src="https://raw.githubusercontent.com/bedimcode/responsive-login-signin-signup/b3c2eaa19d76624092bd606d28fbd616d539de92/assets/img/img-login.svg" alt="" />
            </div>

            <div className="login__forms loginform_reg">
              <form onSubmit={HandleSubmit} className="login__registre" id="login-in">
                <h1 className="login__title">CREATE ACCOUNT</h1>

                <div className="login__box">
                  <i className="bx bx-user login__icon"></i>
                  <input type="text" placeholder="Username" className="login__input" required />
                </div>

                <div className="login__box">
                  <i className="bx bx-at login__icon" ></i>
                  <input type="email" placeholder="E-Mail" className="login__input" required />
                </div>

                <div className="login__box">
                  <i className="bx bx-lock-alt login__icon"></i>
                  <input type="password" placeholder="Password" className="login__input" required />
                </div>

                <div className="login__box_pfp">
                  <input type="file" accept="image/png, image/jpeg" placeholder="Image" className="login__input" id="File" style={{ display: "none" }} onChange={(e) => handleImageChange(e)} />
                  <label htmlFor="File" className="flex flex-row space-x-4">
                    <i className="bx bxs-file-image login__icon cursor-pointer"></i>
                    <h1 className="login__input cursor-pointer break-all">{text === "" ? "Add A Profile Picture" : text}</h1>
                  </label>
                </div>
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">Something went wrong</span>}<br />
                {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorCode}</span>}
                {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorMessage}</span>} */}
                {loading && <button className="login__button cursor-pointer w-full" disabled="true">Creating Account</button>}
                {!loading && <button className="login__button cursor-pointer w-full">Create Account</button>}

                {!loading &&
                  <>
                    <div>
                      <span className="login__account">Already a User? </span>
                      <span className="login__signin cursor-pointer" id="sign-up" onClick={() => navigate("/Login")}>Sign In</span>
                      <br />
                      <span className="login__signin cursor-pointer" id="sign-up" onClick={() => navigate("/")}>RETURN HOME</span>
                    </div>
                  </>
                }
              </form>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

export default Register