import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <>
      <div className="errorpage" id="errorBody">
        <title>404 | Error Page Not Found</title>
        <h1>404 : Error Page</h1>
        <p className="zoom-area">
          <b>LOST ={">"} </b> You Have Been Redirected To a Page Which Does Not
          Exist.{" "}
        </p>
        <section className="error-container">
          <span className="four">
            <span className="screen-reader-text">4</span>
          </span>
          <span className="zero">
            <span className="screen-reader-text">0</span>
          </span>
          <span className="four">
            <span className="screen-reader-text">4</span>
          </span>
        </section>
        <div className="link-container">
          <button onClick={() => navigate(-1)} className="more-link-button">
            Go Back
          </button>
          <a className="more-link cursor-pointer" onClick={() => navigate("/")}>
            Return Home
          </a>
        </div>
      </div>
    </>
  );
}
