import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import { doc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import SearchBar from "../components/SearchBar";

const Profile = (props) => {
    document.title = "Drake Forum • Profile"
    const [loggingOut, setLoggingOut] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");

    if (!currentUser) {
        navigate("/")
    }

    useEffect(() => {
        const interval = setInterval(() => {
        var current = new Date();
        var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
        var CurrentDate = current.toLocaleDateString();
        setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const HandleSignOut = async (e) => {
        setLoggingOut(true)
        await updateDoc(doc(db, "Users", currentUser.uid), {
            Status: "Offline Since",
            LogoutTime: combinedCurrentDateTime,
            SetStatusDone:false,
        });
        removeCookie("user");
        props.ChangeUserData('');
        signOut(auth);
        setLoggingOut(false);
        document.title = "Drake Forum • Login";
        navigate("/");
    }
    
    if (loggingOut) {
        document.title = "Drake Forum • Logging Out";
    }

  
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <div className="header-menu top-menu">
                        <a className="menu-link is-active" href="/">Home</a>
                        <a className="menu-link notify" href="/">Contact</a>
                        <a className="menu-link" href="/">Login</a>
                        <a className="menu-link notify" href="/">Register</a>

                        <div className="header-menu">
                            <a className="menu-link" href="/">Login</a>
                        </div>

                    </div>
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h1 className="img-content font-extrabold">{currentUser.displayName}</h1>
                                    <div className="content-text">E-Mail : {currentUser.email}</div>
                                    <div className="content-text">No. Of Questions Asked : {cookies["user"].NoOfQuestionAsked}</div>
                                    <div className="content-text">No. Of Questions Answered : {cookies["user"].NoOfQuestionAnswered}</div>
                                    <div className="content-text">Last Login Time : {cookies["user"].LoginTime.replace('am',"A.M").replace('pm','P.M')}</div>
                                    <button className="content-button" onClick={HandleSignOut}>LOGOUT</button>
                                </div>
                            </div>
                            <div className="content-section">
                                <div className="content-section-title" style={{textTransform:"uppercase"}}>Update Profile Settings</div>
                                <div className="apps-card">
                                    <div className="app-card-profile">
                                        <span>
                                            Username
                                        </span>
                                        <div className="app-card__subtext">Change your Username Here</div>
                                        <div className="app-card-buttons">
                                            <button className="content-button status-button" onClick={() => navigate("/Profile/UpdateUsername")}>Change</button>
                                        </div>
                                    </div>
                                    <div className="app-card-profile">
                                        <span>
                                            E-Mail
                                        </span>
                                        <div className="app-card__subtext">Change your E-Mail Here</div>
                                        <div className="app-card-buttons">
                                            <button className="content-button status-button" onClick={() => navigate("/Profile/UpdateEMail")}>Change</button>
                                        </div>
                                    </div>
                                    <div className="app-card-profile">
                                        <span>
                                            Password
                                        </span>
                                        <div className="app-card__subtext">Change your Password Here</div>
                                        <div className="app-card-buttons">
                                            <button className="content-button status-button" onClick={() => navigate("/Profile/UpdatePassword")}>Change</button>
                                        </div>
                                    </div>
                                    <div className="app-card-profile">
                                        <span>
                                            Profile Picture
                                        </span>
                                        <div className="app-card__subtext">Change your Profile Picture Here</div>
                                        <div className="app-card-buttons">
                                            <button className="content-button status-button" onClick={() => navigate("/Profile/UpdateProfilePicture")}>Change</button>
                                        </div>
                                    </div>
                                    <div className="app-card-profile">
                                        <span>
                                            Delete Account
                                        </span>
                                        <div className="app-card__subtext">You can delete your account Here</div>
                                        <div className="app-card-buttons">
                                            <button className="content-button status-button" onClick={() => navigate("/Profile/DeleteAccount")}>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Profile
