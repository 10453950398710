import React,{useContext,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { doc,setDoc } from "firebase/firestore";
import { db } from "../firebase";
import SearchBar from "../components/SearchBar";
import swal from '@sweetalert/with-react';

const Home = (props) => {
    document.title = "Drake Forum • Contact"

    const { currentUser } = useContext(AuthContext);
    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);
    const navigate = useNavigate()
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
        var current = new Date();
        var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
        var CurrentDate = current.toLocaleDateString();
        setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const HandleSubmit = async(e) => {
        e.preventDefault();

        const name = e.target[0].value;
        const email = e.target[1].value;
        const msg = e.target[2].value;

        const CombinedNameTime = name + " " + email;

        await setDoc(doc(db, "ContactForm", CombinedNameTime.replaceAll(" ","_")), {
            Name: name,
            EMail: email,
            Comment: msg,
            Time: combinedCurrentDateTime,
            DeviceIP:props.IPAddress,
        });
        swal("Your Message/Feedback Is Received!!");
        navigate("/");
    }

    
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='ContactBody'>
            <div className="app">
                <div className="header">
                    <div className="header-menu top-menu">
                        <a className="menu-link is-active" href="/">Home</a>
                        <a className="menu-link notify" href="/">Contact</a>
                        <a className="menu-link" href="/">Login</a>
                        <a className="menu-link notify" href="/">Register</a>

                        <div className="header-menu">
                            <a className="menu-link" href="/">Login</a>
                        </div>

                        <img className="profile-img" src="https://images.unsplash.com/photo-1600353068440-6361ef3a86e8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80" alt="" />

                    </div>
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer is-active" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <form onSubmit={HandleSubmit} className="ContactForm">
                                <div className="ContactFormInput">
                                    <input type="text" placeholder="Name" required/>
                                </div>
                                <div className="ContactFormInput">
                                    <input type="email" placeholder="E-Mail" required/>
                                </div>
                                <div className="ContactFormTextarea">
                                    <textarea name="FeedbackForm" id="FeedbackArea" placeholder="Type Your Message/Feedback Here..." required></textarea>
                                </div>
                                <button className="content-button">SUBMIT</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Home
