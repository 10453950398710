import React, { useContext,useEffect,useState } from 'react';
import { doc, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from "uuid";
import swal from '@sweetalert/with-react';
import SearchBar from "../components/SearchBar";

const Create = (props) => {

    const navigate = useNavigate()
    const { currentUser } = useContext(AuthContext);
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");

    if (!currentUser) {
      navigate("/")
    }

    document.title = "Drake Forum • Create"

    const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
        var current = new Date();
        var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
        var CurrentDate = current.toLocaleDateString();
        setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const HandleSubmit = async(e) => {
        e.preventDefault();

        const categoryName = e.target[0].value;

        const UniqueID = uuid()
        
        await updateDoc(doc(db, "Topic", "Name"), {
            categories: arrayUnion({
              id: UniqueID,
              Subject: categoryName,
              IP: props.IPAddress,
              Time: combinedCurrentDateTime,
              UserName: currentUser.displayName
            }),
        });
        
        e.target[0].value = ""

        swal(`Created ${categoryName} Category`)
    }

    
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">Create Category</h3>
                                    <div className="content-text capitalize">By creating a new category you will make a new branch of queries and results</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <form onSubmit={HandleSubmit} className="CategoryForm">
                                    <div className="CategoryName">
                                        <input type="text" placeholder="Enter A Name For New Category" required/>
                                    </div>
                                    <button className="content-button">Create Category</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Create