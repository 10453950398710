import React, { useContext,useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
// import swal from '@sweetalert/with-react';
// import * as LoadingSpinner from "react-spinners-kit";
import SearchBar from "../components/SearchBar";

const Search = (props) => {
    const { SearchValue } = useParams();
    document.title = `Drake Forum • Search - ${SearchValue.replaceAll("-"," ")}`;
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchText,setSearchText] = useState(props.SearchText);
  
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])
  
    useEffect(() => {
        props.GetSearchKeyword(SearchValue.replaceAll("-"," "))
    }, [])

    const NavigateToQuestionsPage = async(value) => {
        navigate("/Questions/"+value.CatID+"/"+value.id+"/"+value.Question.replaceAll(' ', '-'))
    }
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">Drake Forum • Search - {SearchValue.replaceAll("-"," ")}</h3>
                                </div>
                            </div>
                            <div className="content-section">
                                <div className="content-section-title">QUESTIONS</div>
                                <div className="apps-card">
                                    {props.SearchedQuestionsData === "NO DATA"
                                        ?
                                            <div className="app-card" style={{justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                                                <h1>No Questions Found</h1>
                                            </div>
                                        :
                                            props.SearchedQuestionsData.map(quest => (
                                                <>
                                                    <div className="app-card" id={quest.id} key={quest.id} onClick={() => NavigateToQuestionsPage(quest)}>
                                                        <span>
                                                            {quest.Question}
                                                        </span>
                                                        {quest.QuestionScreenShot
                                                            ?
                                                                <>
                                                                    <div className="app-card-img">
                                                                        <img src={quest.QuestionScreenShot} alt="" />
                                                                    </div>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        <div className="app-card__subtext">This question is asked by {quest.UserName}</div>
                                                    </div>
                                                </>
                                            ))
                                    }
                                </div>
                                <div className="content-section-title mt-10">ANSWERS</div>
                                <div className="apps-card">
                                    {props.SearchedAnswersData === "NO DATA"
                                        ?
                                            <div className="app-card-ans" style={{justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                                                <h1>No Answers Found</h1>
                                            </div>
                                        :
                                            props.SearchedAnswersData.sort((a, b) => a.date - b.date).map(ans => (
                                                <>
                                                    <div className="app-card-ans" id={ans.id} key={ans.id}>
                                                        <span>
                                                            {ans.Answer}
                                                        </span>
                                                        {ans.AnswerScreenShot
                                                            ?
                                                                <>
                                                                    <div className="app-card-img">
                                                                        <img src={ans.AnswerScreenShot} alt="" />
                                                                    </div>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        <div className="app-card__subtext font-extrabold">This question is answered by {ans.UserName}</div>
                                                    </div>
                                                </>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default Search