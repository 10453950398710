import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { deleteUser, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import SearchBar from "../components/SearchBar";
import swal from "@sweetalert/with-react";

const DeleteAccount = (props) => {
    document.title = "Drake Forum • Update Password"
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [searchText,setSearchText] = useState(props.SearchText);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [err, setErr] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const HandleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const EMAIL = e.target[0].value;
        if (EMAIL === currentUser.email) {
            swal({
                title: "Are You Sure? You Want To Delete The Account",
                text: "Once Deleted, It Can't Be Undone!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    deleteUser(currentUser).then(() => {
                        swal("Account Deleted", {
                            icon: "success",
                        })
                    }).catch((error) => {
                        setErr(true);
                        setLoading(false);
                        setErrorCode(error.code);
                        setErrorMessage(error.message);
                        if (error.code === "auth/requires-recent-login") {
                            swal("Authorization Credentials Expired, Signing Out. Please Sign In Again")
                            signOut(auth)
                            window.location.reload()
                        }
                    });
                } else {
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
            swal({
                title: "E-Mail ID Does Not Match",
                text: "Please Enter The Correct E-Mail ID For Deleting Account!",
                icon: "warning"
            })
        }
    }

    if (!currentUser) {
        navigate("/")
    }
  
    useEffect(() => {
        props.SendSearchText(searchText)
    }, [searchText])

    
    // useEffect(() => {
    //     props.GetSearchKeyword(searchKeyword);
    // }, [searchKeyword])
    
    return (
        <div className='HomeBody'>
            <div className="app">
                <div className="header">
                    <div className="header-menu top-menu">
                        <a className="menu-link is-active" href="/">Home</a>
                        <a className="menu-link notify" href="/">Contact</a>
                        <a className="menu-link" href="/">Login</a>
                        <a className="menu-link notify" href="/">Register</a>

                        <div className="header-menu">
                            <a className="menu-link" href="/">Login</a>
                        </div>

                    </div>
                    <SearchBar SearchText={props.SearchText} SearchBarText={(value) => setSearchText(value)} GetSearchKeyword={(value) => props.GetSearchKeyword(value)}/>
                    <div className="header-profile">
                        {currentUser
                            ? 
                                <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" onClick={() => navigate("/Profile")}/>
                            :
                                <div className="header-menu cursor-pointer text-slate-300" onClick={() => navigate("/Login")}>
                                    Login
                                </div>
                        }
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header">
                            <div className="header-menu">
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/")}>Home</a>
                                <a className="main-header-link cursor-pointer" onClick={() => navigate("/Contact")}>Contact</a>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            <div className="content-wrapper-header">
                                <div className="content-wrapper-context">
                                    <h3 className="img-content">DELETE ACCOUNT</h3>
                                    <div className="content-text capitalize">Please think before deleting your account</div>
                                </div>
                            </div>
                            <div className="content-section">
                                <form onSubmit={HandleSubmit} className="CategoryForm">
                                    <div className="CategoryName">
                                        <input type="email" placeholder="ENTER YOUR E-MAIL" required/>
                                    </div>
                                    <button className="content-button">DELETE</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay-app" />
            </div>
        </div>
    )
}

export default DeleteAccount